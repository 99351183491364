/* Images */

.imageIcon {
  border-radius: var(--size-16);
  width: var(--size-32);
  height: var(--size-32);
  overflow: hidden;
  object-fit: cover;
  color: var(--surface-secondary);
}

/* Text */

.containerText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--size-12);
}

.textNumber,
.textTitle {
  font-size: var(--size-24);
  font-weight: var(--size-40);
  font-weight: var(--font-bold);
  font-family: inherit;
  letter-spacing: var(--letter-spacing-default);
}

.textNumber {
  color: var(--on-surface-accent-active);
}

.textTitle {
  color: var(--surface-secondary);
}

.textParagraph {
  font-size: var(--size-14);
  line-height: var(--size-24);
  font-weight: var(--font-semibold);
  font-family: inherit;
  color: var(--on-surface-accent-inactive);
}

/* Main Containers */

.containerCorner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: var(--size-64);
  height: var(--size-64);
  overflow: hidden;
  top: var(--size-0);
  right: var(--size-0);
  background-color: var(--surface-secondary);
  border-radius: var(--size-0) var(--size-4) var(--size-0) var(--size-32);
}

.containerCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  position: relative;
  gap: var(--size-16);
  width: 100%;
  background-color: var(--surface-primary);
  border: 1px solid var(--on-surface-accent-disabled-dark);
  border-radius: var(--size-16);
  align-items: flex-end;
  padding: var(--size-104) var(--size-32) var(--size-32) var(--size-32);
  box-sizing: border-box;
  overflow: hidden;
  transition: calc(var(--d) * 2) var(--e);
  cursor: default;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: var(--surface-secondary);
    height: var(--size-32);
    width: var(--size-32);
    border-radius: var(--size-32);
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: calc(var(--d) * 2) var(--e);
  }

  &:hover:before {
    transform: scale(28);
  }

  &:hover .textTitle {
    color: var(--surface-primary);
    transition: calc(var(--d) * 2) var(--e);
  }

  &:hover .textParagraph {
    color: var(--on-surface-accent-disabled-dark);
    transition: calc(var(--d) * 2) var(--e);
  }
}

.containerCard:hover {
  transition: calc(var(--d) * 2) var(--e);
  border-radius: var(--size-32);
  transform: scale(1.1, 1.1);
}

@media screen and (max-width: 1536px) {
  .containerCard {
    padding: var(--size-64) var(--size-20) var(--size-20) var(--size-20);
  }

  .textTitle,
  .textNumber {
    font-size: var(--size-20);
    line-height: var(--size-32);
  }
}

@media screen and (max-width: 1440px) {
  .textParagraph {
    font-size: var(--size-12);
  }

  .containerCard:hover {
    transform: scale(1.075, 1.075);
  }
}

@media screen and (max-width: 1366px) {
  .containerCard {
    padding: var(--size-64) var(--size-16) var(--size-16) var(--size-16);
    border-radius: var(--size-12);
  }

  .containerCard:hover {
    border-radius: var(--size-24);
    transform: scale(1.05, 1.05);
  }

  .textTitle {
    font-size: var(--size-16);
    line-height: var(--size-32);
  }

  .containerText {
    gap: var(--size-8);
  }

  .imageIcon {
    width: var(--size-24);
    height: var(--size-24);
  }

  .containerCorner {
    width: var(--size-56);
    height: var(--size-56);
    border-radius: var(--size-0) var(--size-4) var(--size-0) var(--size-24);
  }
}

@media screen and (max-width: 1024px) {
  .containerCard {
    height: 100%;
  }
  
  .containerText {
    gap: var(--size-4);
  }

  .textParagraph {
    line-height: var(--size-20);
  }
}
