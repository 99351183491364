@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: roagBlack;
  src: url(/public/fonts/ROAG-BLACK.OTF);
}

:root {
  --d: 700ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);

  /* alphas */
  --alpha-20: 0.2;

  /* fonts */
  --font-main: Inter;
  --font-roag: roagBlack;

  /* font weights */
  --font-thin: 100;
  --font-extralight: 200;
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --font-black: 900;

  /* letter spacing */
  --letter-spacing-default: -0.04rem;
  --letter-spacing-header-big: -0.25rem;
  --letter-spacing-header-small: -0.125rem;

  /* Sizes */
  --size-0: 0rem;
  --size-2: 0.125rem;
  --size-3: 0.1875rem;
  --size-4: 0.25rem;
  --size-6: 0.375rem;
  --size-8: 0.5rem;
  --size-10: 0.625rem;
  --size-12: 0.75rem;
  --size-14: 0.875rem;
  --size-16: 1rem;
  --size-18: 1.125rem;
  --size-20: 1.25rem;
  --size-24: 1.5rem;
  --size-28: 1.75rem;
  --size-32: 2rem;
  --size-34: 2.125rem;
  --size-36: 2.25rem;
  --size-40: 2.5rem;
  --size-48: 3rem;
  --size-56: 3.5rem;
  --size-64: 4rem;
  --size-72: 4.5rem;
  --size-80: 5rem;
  --size-88: 5.5rem;
  --size-96: 6rem;
  --size-104: 6.5rem;
  --size-112: 7rem;
  --size-120: 7.5rem;
  --size-128: 8rem;
  --size-136: 8.5rem;
  --size-144: 9rem;
  --size-152: 9.5rem;
  --size-160: 10rem;
  --size-168: 10.5rem;
  --size-176: 11rem;
  --size-184: 11.5rem;
  --size-192: 12rem;
  --size-200: 12.5rem;
  --size-208: 13rem;
  --size-216: 13.5rem;
  --size-224: 14rem;
  --size-240: 15rem;
  --size-256: 16rem;
  --size-264: 16.5rem;
  --size-320: 20rem;
  --size-360: 22.5rem;
  --size-400: 25rem;
  --size-560: 35rem;
  --size-720: 45rem;

  /* Colors ROSA */
  --surface-tertiary: #0e1012;
  --surface-primary: #fff;
  --surface-secondary: #181f4e;
  --on-surface-accent-active: #fab300;
  --on-surface-accent-inactive: #7e84b5;
  --on-surface-accent-disabled-light: #e9edef;
  --on-surface-accent-disabled-dark: #c8d2d8;

  /* Colors ROSA */
  --colorFootballRulesGreen: #00f481;

  /* Effects */
  --shadow-card: -176px 226px 80px rgba(23, 31, 78, 0),
    -113px 144px 73px rgba(23, 31, 78, 0.01),
    -63px 81px 62px rgba(23, 31, 78, 0.04),
    -28px 36px 46px rgba(23, 31, 78, 0.07), -7px 9px 25px rgba(23, 31, 78, 0.08);
  --shadow-mockup-left: 32px 32px 64px rgba(0, 0, 0, 0.24);
  --shadow-mockup-hover-left: 64px 64px 128px rgba(0, 0, 0, 0.48);
  --shadow-mockup-right: -32px 32px 64px rgba(0, 0, 0, 0.24);
  --shadow-mockup-hover-right: -64px 64px 128px rgba(0, 0, 0, 0.48);

  --shadow-hero-mockup-large: var(--size-40) var(--size-40) var(--size-120)
    rgba(0, 0, 0, 0.8);
  --shadow-hero-mockup-big: var(--size-32) var(--size-32) var(--size-96)
    rgba(0, 0, 0, 0.8);
  --shadow-hero-mockup-medium: var(--size-24) var(--size-24) var(--size-72)
    rgba(0, 0, 0, 0.8);
  --shadow-hero-mockup-small: var(--size-16) var(--size-16) var(--size-48)
    rgba(0, 0, 0, 0.8);
}

html {
  font-size: 16px;
  font-family: var(--font-main);
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  line-height: normal;
  background-color: var(--surface-primary);
  font-family: var(--font-main);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

p {
  margin: 0;
  line-height: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

hr {
  margin: 0;
  line-height: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  align-self: stretch;
  position: relative;
  letter-spacing: var(--letter-spacing-default);
}

h1 {
  font-size: var(--size-80);
  line-height: var(--size-80);
}

h2 {
  font-size: var(--size-64);
  line-height: var(--size-80);
}

h3 {
  font-size: var(--size-56);
  line-height: var(--size-72);
}

h4 {
  font-size: var(--size-48);
  line-height: var(--size-64);
}

h5 {
  font-size: var(--size-40);
  line-height: var(--size-56);
}

h6 {
  font-size: var(--size-32);
  line-height: var(--size-48);
}
