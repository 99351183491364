/* Button Icons */

.iconRight,
.iconLeft {
  position: relative;
  width: var(--size-24);
  height: var(--size-24);
  overflow: hidden;
  flex-shrink: 0;
}

/* Button Text */

.textButton {
  font-size: var(--size-16);
  line-height: var(--size-32);
  font-weight: var(--font-black);
  color: var(--surface-secondary);
}

/* Main Containers */

.containerButton {
  background-color: var(--on-surface-accent-active);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
}

.containerButton a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: var(--size-12) var(--size-16);
}

/* Media Queries */

@media screen and (max-width: 1536px) {
  .textButton {
    font-size: var(--size-14);
    line-height: var(--size-24);
    font-weight: var(--font-black);
    color: var(--surface-secondary);
  }
}
