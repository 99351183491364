/* Shared styles for .imageIcon and .textBullet */
.imageIcon,
.textBullet {
  position: relative;
}

/* Images */
.imageIcon {
  width: var(--size-32);
  height: var(--size-32);
}

/* Text */
.textBullet {
  flex: 1;
  font-size: var(--size-20);
  font-weight: var(--font-semibold);
  line-height: var(--size-32);
}

/* Main Containers */
.containerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--size-16);
  text-align: left;
  align-self: stretch;
}

/* Media Queries */
@media screen and (max-width: 1536px) {
  .textBullet {
    font-size: var(--size-16);
    line-height: var(--size-24);
  }

  .imageIcon {
    width: var(--size-24);
    height: var(--size-24);
  }
}

@media screen and (max-width: 1280px) {
  .textBullet {
    font-size: var(--size-14);
    line-height: var(--size-20);
  }

  .imageIcon {
    width: var(--size-20);
    height: var(--size-20);
  }
}
