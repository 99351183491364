.containerParallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  width: 100vw;
}

.containerScroller {
  display: flex;
  white-space: nowrap;
  flex-wrap: nowrap;
}

@media screen and (max-width: 1536px) {
  .image {
    max-height: var(--size-144);
  }
}

@media screen and (max-width: 1366px) {
  .image {
    max-height: var(--size-128);
  }
}

@media screen and (max-width: 768px) {
  .image {
    max-height: var(--size-104);
  }
}