.containerContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--size-32);
  height: fit-content;
  position: sticky;
  top: -200px
}
