/* Scroll To Top */

.containerButton {
  width: var(--size-160);
  aspect-ratio: 1/1;
  height: auto;
  display: flex;
  position: relative;
  transition: calc(var(--d) * 2) var(--e);
}

.imageScroll {
  position: relative;
  width: var(--size-160);
  height: auto;
  object-fit: cover;
}

.iconScroll {
  position: absolute;
  top: calc(50% - (var(--size-24) / 2));
  left: calc(50% - (var(--size-24) / 2));
  width: var(--size-24);
  height: var(--size-24);
}

@media screen and (max-width: 1536px) {
  .containerButton,
  .imageScroll {
    width: var(--size-144);
  }
}

@media screen and (max-width: 1280px) {
  .containerButton,
  .imageScroll {
    width: var(--size-128);
  }
}
