.containerText {
  font-size: var(--size-24);
  line-height: var(--size-32);
  color: var(--surface-secondary);
}

@media screen and (max-width: 820px) {
  .containerText {
    font-size: var(--size-20);
  }
}
