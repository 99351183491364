/* Images */

.imageLogo {
  width: auto;
  height: var(--size-32);
}

/* Button Container */
.containerMenu {
  display: flex;
  position: relative;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 100;
  transition: calc(var(--d) * 2) var(--e);
}

.containerMenu.active .containerMenuTop {
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: var(--surface-primary);
}

.containerMenu.active .containerMenuMiddle {
  opacity: 0;
  background: var(--surface-primary);
}

.containerMenu.active .containerMenuBottom {
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: var(--surface-primary);
}

.containerMenu span {
  background: var(--surface-primary);
  border: none;
  height: var(--size-2);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: calc(var(--d) * 2) var(--e);
  cursor: pointer;
}
.containerMenu span:nth-of-type(2) {
  top: 11px;
}
.containerMenu span:nth-of-type(3) {
  top: 22px;
}

/* Main Containers */

.containerContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 960px;
}

.containerSection {
  width: 100%;
  max-width: 1520px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.containerNav {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  padding: var(--size-20) var(--size-200);
  transition: calc(var(--d) * 2) var(--e);
}

.containerNavActive {
  background-color: var(--surface-primary);
  box-shadow: var(--shadow-card);
  transition: calc(var(--d) * 2) var(--e);
}

/* Media Queries */

@media screen and (max-width: 1536px) {
  .containerNav {
    padding: var(--size-16) var(--size-104);
  }

  .imageLogo {
    height: var(--size-24);
  }

  .containerContent {
    max-width: 800px;
  }
}

@media screen and (max-width: 1366px) {
  .containerNav {
    padding: var(--size-16) var(--size-80);
  }
}

@media screen and (max-width: 1280px) {
  .containerNav {
    padding: var(--size-16) var(--size-64);
  }

  .containerContent {
    max-width: 640px;
  }
}

@media screen and (max-width: 1024px) {
  .containerNav {
    padding: var(--size-24) var(--size-48);
    justify-content: center;
    align-items: center;
    position: absolute;
  }
}

@media screen and (max-width: 768px) {
  .containerNav {
    padding: var(--size-24) var(--size-24);
  }
}

@media screen and (max-width: 376px) {
  .containerNav {
    padding: var(--size-24) var(--size-16);
  }
}
