/* Animations */

@keyframes imageLeft {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(var(--size-16));
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes imageRight {
  0% {
    transform: translateY(var(--size-16));
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(var(--size-16));
  }
}

/* Scroll Down */

.containerScrollMotion {
  position: absolute;
  left: calc(50% - var(--size-80));
  bottom: var(--size-48);
}

/* Images */

.containerImage {
  width: 100%;
}

.containerMotion {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 48%;
  height: fit-content;
  animation-name: containerMotion;
  animation-duration: 2s;
  animation-timing-function: calc(var(--d) * 2) var(--e);
  transition: calc(var(--d) * 2) var(--e);
}

.imageLeft {
  width: 64%;
  height: auto;
  position: relative;
  z-index: 2;
  filter: drop-shadow(var(--shadow-hero-mockup-large));
  animation-name: imageLeft;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  object-fit: contain;
}

.imageRight {
  width: 60%;
  position: absolute;
  right: 0;
  bottom: 0;
  filter: drop-shadow(var(--shadow-hero-mockup-large));
  animation-name: imageRight;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  object-fit: contain;
}

/* Main Containers */

.containerContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1920px;
  gap: var(--size-128);
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--size-0) var(--size-200);
  box-sizing: border-box;
}

.containerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  box-sizing: border-box;
  background-image: url("/public/backgrounds/backgroundHero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
}

/* Media Queries */

@media screen and (max-width: 1536px) {
  .containerContent {
    padding: var(--size-160) var(--size-104);
    gap: var(--size-128);
  }

  .containerMotion {
    max-width: 50%;
    max-height: 640px;
  }

  .imageLeft,
  .imageRight {
    filter: drop-shadow(var(--shadow-hero-mockup-big));
  }

  .imageLeft {
    width: 54%;
  }

  .imageRight {
    width: 50%;
    right: 8%;
  }

  .containerScrollMotion {
    left: calc(50% - var(--size-72));
    bottom: var(--size-32);
  }
}

@media screen and (max-width: 1440px) {
  .containerContent {
    gap: var(--size-80);
  }

  .imageLeft {
    width: 58%;
  }

  .imageRight {
    width: 54%;
  }
}

@media screen and (max-width: 1366px) {
  .containerContent {
    padding: var(--size-128) var(--size-80);
    gap: var(--size-64);
  }

  .containerMotion {
    max-width: 48%;
    height: 520px;
  }

  .imageLeft,
  .imageRight {
    filter: drop-shadow(var(--shadow-hero-mockup-medium));
  }
}

@media screen and (max-width: 1280px) {
  .containerContent {
    padding: var(--size-128) var(--size-64);
  }

  .containerMotion {
    max-width: 42%;
    max-height: 420px;
  }

  .imageLeft,
  .imageRight {
    filter: drop-shadow(var(--shadow-hero-mockup-small));
  }

  .imageRight {
    width: 54%;
    bottom: -8%;
  }

  .containerScrollMotion {
    left: calc(50% - var(--size-64));
  }
}

@media screen and (max-width: 1024px) {
  .containerSection {
    align-items: flex-start;
    justify-content: flex-start;
    height: fit-content;
  }

  .containerContent {
    padding: var(--size-128) var(--size-48) var(--size-80) var(--size-48);
    flex-direction: column;
    gap: var(--size-48);
    align-items: center;
    justify-content: flex-start;
    height: fit-content
  }

  .containerMotion {
    pointer-events: none;
    max-width: 100%;
    width: 64%;
    height: fit-content;
    max-height: 100%;
  }

  .imageLeft {
    width: 60%;
  }

  .imageRight {
    width: 56%;
    bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .containerContent {
    padding: var(--size-128) var(--size-16) var(--size-48) var(--size-16);
    gap: var(--size-40);
  }

  .containerMotion {
    width: 100%;
    height: fit-content;
  }

  .imageLeft {
    height: var(--size-400);
  }

  .imageRight {
    height: var(--size-360);
  }
}
