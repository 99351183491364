.linkMobile {
  text-align: center;
  font-size: var(--size-24);
  line-height: var(--size-64);
  color: var(--surface-primary);
  font-weight: var(--font-medium);
}

/* Main Containers */

.linkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}