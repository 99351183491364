/* Images */

.imageLogo {
  width: auto;
  height: var(--size-32);
}

/* Main Containers */

.containerContent {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 800px;
}

.containerSection {
  width: 100%;
  max-width: 1520px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.containerNav {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  padding: var(--size-20) var(--size-200);
  transition: calc(var(--d) * 2) var(--e);
}
/* Media Queries */

@media screen and (max-width: 1536px) {
  .containerNav {
    padding: var(--size-16) var(--size-104);
  }

  .imageLogo {
    height: var(--size-24);
  }
}

@media screen and (max-width: 1366px) {
  .containerNav {
    padding: var(--size-16) var(--size-80);
  }

  .containerContent {
    max-width: 800px;
  }
}

@media screen and (max-width: 1280px) {
  .containerNav {
    padding: var(--size-16) var(--size-64);
  }

  .containerContent {
    max-width: 640px;
  }
}

@media screen and (max-width: 1024px) {
  .containerNav {
    padding: var(--size-24) var(--size-48);
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .containerNav {
    padding: var(--size-24) var(--size-24);
  }
}

@media screen and (max-width: 376px) {
  .containerNav {
    padding: var(--size-24) var(--size-16);
  }
}
