@keyframes containerOrnamentWhite {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(var(--size-64));
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes containerOrnamentOrange {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(var(--size-32));
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes containerOrnamentBlue {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(var(--size-128));
  }
  100% {
    transform: translateY(0);
  }
}

.containerOrnamentWhite,
.containerOrnamentBlue,
.containerOrnamentOrange {
  animation-duration: 8s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.containerOrnamentWhite {
  background-color: var(--surface-primary);
  width: var(--size-80);
  height: var(--size-80);
  position: absolute;
  top: var(--size-40);
  right: var(--size-40);
  animation-name: containerOrnamentWhite;
  z-index: -1;
}

.containerOrnamentOrange {
  background-color: var(--on-surface-accent-active);
  width: var(--size-560);
  height: var(--size-560);
  position: absolute;
  margin: auto auto;
  animation-name: containerOrnamentOrange;
  z-index: -2;
}

.containerOrnamentBlue {
  background-color: var(--on-surface-accent-inactive);
  width: var(--size-48);
  height: var(--size-48);
  position: absolute;
  bottom: var(--size-160);
  left: var(--size-40);
  animation-name: containerOrnamentBlue;
  z-index: -1;
}

.textNumber {
  position: relative;
  letter-spacing: var(--letter-spacing-default);
  font-weight: var(--font-black);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size-40);
  height: var(--size-40);
  flex-shrink: 0;
  color: var(--surface-secondary);
  font-size: var(--size-20);
  line-height: var(--size-40);
}

.textTitle,
.textParagraph .textTags {
  align-self: stretch;
  position: relative;
  color: var(--surface-primary);
}

.textTitle {
  font-weight: var(--font-semibold);
  font-size: var(--size-20);
  line-height: var(--size-40);
}

.textParagraph {
  font-size: var(--size-16);
  line-height: var(--size-32);
  font-weight: var(--font-regular);
  color: var(--on-surface-accent-disabled-light);
  height: var(--size-64);
}

.textTags {
  font-size: var(--size-14);
  line-height: var(--size-24);
  font-weight: var(--font-bold);
  color: var(--on-surface-accent-disabled-light);
  width: 100%;
}

/* Main Containers */

.containerText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: var(--size-12);
}

.containerImage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: var(--size-720);
  max-height: var(--size-720);
  width: 50%;
  transition: calc(var(--d) * 2) var(--e);
  &:hover {
    transform: scale(1.025) rotate(-2.5deg);
    transition: calc(var(--d) * 2) var(--e);
  }
}

.containerMockup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size-320);
  height: calc(var(--size-720) * 0.99);
  overflow: hidden;
  border-radius: var(--size-48);
}

.containerDevice {
  width: 348px;
  position: absolute;
  margin: 0 auto;
  z-index: 999;
}

.image {
  aspect-ratio: auto;
  height: auto;
  margin: auto auto;
}

.containerMask {
  height: calc(var(--size-720) * 0.99);
  width: var(--size-320);
  border-radius: var(--size-48);
  overflow: hidden;
}

.containerCard {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  align-items: flex-start;
  padding: var(--size-20);
  box-sizing: border-box;
  overflow: hidden;
  transition: calc(var(--d) * 2) var(--e);
  cursor: pointer;
  position: relative;
  backdrop-filter: blur(var(--size-12));
  background-color: rgba(0, 0, 0, 0.2);
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -32px;
    right: -32px;
    background: var(--surface-primary);
    height: var(--size-32);
    width: var(--size-32);
    border-radius: var(--size-32);
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: calc(var(--d) * 2) var(--e);
  }
}

.containerCard.cardHovered {
  transition: calc(var(--d) * 2) var(--e);
  transform: scale(1.05, 1.05);
}

.containerCard.cardHovered:before {
  transform: scale(52);
}

.containerCard.cardHovered .textTitle,
.containerCard.cardHovered .textParagraph,
.containerCard.cardHovered .textTags {
  color: var(--surface-secondary);
  transition: calc(var(--d) * 2) var(--e);
}

.containerCorner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: var(--size-56);
  height: auto;
  aspect-ratio: 1/1;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: var(--surface-primary);
  border-radius: 0 var(--size-4) 0 var(--size-24);
}

.containerCards {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
  max-width: 50%;
}

.containerMotion {
  width: 100%;
  max-width: 1520px;
}

.containerContent {
  width: 100%;
  max-width: 1520px;
  display: flex;
  flex-direction: row;
  gap: var(--size-80);
  align-items: flex-start;
  justify-content: flex-start;
}

.containerSection {
  width: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--size-128) var(--size-200);
  position: relative;
  gap: var(--size-128);
  background-image: url(/public/backgrounds/backgroundContext.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  box-sizing: border-box;
}

@media screen and (max-width: 1536px) {
  .containerSection {
    padding: var(--size-80) var(--size-104);
    gap: var(--size-96);
  }

  .containerCard {
    gap: var(--size-16);
  }

  .textTitle {
    font-weight: var(--font-semibold);
    font-size: var(--size-18);
    line-height: var(--size-32);
  }

  .containerImage {
    height: calc(var(--size-720) * 0.94);
    max-height: calc(var(--size-720) * 0.94);
  }

  .containerMockup {
    height: calc(var(--size-720) * 0.94);
  }

  .containerDevice {
    aspect-ratio: auto;
    width: auto;
    height: calc(var(--size-720) * 0.94);
  }

  .containerMask {
    width: 307px;
    height: calc(var(--size-720) * 0.94);
  }

  .containerOrnamentBlue {
    width: calc(var(--size-48) * 0.94);
    height: calc(var(--size-48) * 0.94);
    left: var(--size-8);
  }

  .containerOrnamentOrange {
    width: calc(var(--size-560) * 0.94);
    height: calc(var(--size-560) * 0.94);
  }

  .containerOrnamentWhite {
    width: calc(var(--size-80) * 0.94);
    height: calc(var(--size-80) * 0.94);
    right: var(--size-8);
  }
}

@media screen and (max-width: 1440px) {
  .textParagraph {
    font-size: var(--size-14);
    line-height: var(--size-28);
  }

  .textTags {
    font-size: var(--size-12);
    width: 100%;
  }

  .containerOrnamentBlue {
    left: calc(var(--size-16) * -1);
  }

  .containerOrnamentWhite {
    right: calc(var(--size-24) * -1);
  }
}

@media screen and (max-width: 1366px) {
  .containerSection {
    padding: var(--size-80);
  }

  .containerImage {
    height: calc(var(--size-720) * 0.865);
    max-height: calc(var(--size-720) * 0.865);
  }

  .containerMockup {
    height: calc(var(--size-720) * 0.865);
  }

  .containerDevice {
    aspect-ratio: auto;
    width: auto;
    height: calc(var(--size-720) * 0.865);
  }

  .containerMask {
    width: 282px;
    height: calc(var(--size-720) * 0.865);
  }

  .containerOrnamentBlue {
    width: calc(var(--size-48) * 0.865);
    height: calc(var(--size-48) * 0.865);
  }

  .containerOrnamentOrange {
    width: calc(var(--size-560) * 0.865);
    height: calc(var(--size-560) * 0.865);
  }

  .containerOrnamentWhite {
    width: calc(var(--size-80) * 0.865);
    height: calc(var(--size-80) * 0.865);
  }
}

@media screen and (max-width: 1280px) {
  .containerSection {
    padding: var(--size-80) var(--size-64);
    gap: var(--size-80);
  }

  .containerCorner {
    width: var(--size-48);
  }

  .textTitle {
    font-size: var(--size-16);
    line-height: var(--size-24);
  }

  .textNumber {
    font-size: var(--size-16);
  }

  .containerText {
    gap: var(--size-8);
  }

  .textParagraph {
    font-size: var(--size-12);
    line-height: var(--size-20);
    height: var(--size-48);
  }

  .textTags {
    line-height: var(--size-20);
  }

  .containerImage {
    height: calc(var(--size-720) * 0.78);
    max-height: calc(var(--size-720) * 0.78);
  }

  .containerMockup {
    height: calc(var(--size-720) * 0.78);
  }

  .containerDevice {
    aspect-ratio: auto;
    width: auto;
    height: calc(var(--size-720) * 0.78);
  }

  .containerMask {
    width: 251px;
    height: calc(var(--size-720) * 0.78);
  }

  .containerOrnamentBlue {
    width: calc(var(--size-48) * 0.78);
    height: calc(var(--size-48) * 0.78);
    left: var(--size-16);
  }

  .containerOrnamentOrange {
    width: calc(var(--size-560) * 0.78);
    height: calc(var(--size-560) * 0.78);
  }

  .containerOrnamentWhite {
    width: calc(var(--size-80) * 0.78);
    height: calc(var(--size-80) * 0.78);
    right: var(--size-0);
  }
}

@media screen and (max-width: 1024px) {
  .containerSection {
    padding: var(--size-64) var(--size-48);
    gap: var(--size-64);
  }

  .containerOrnamentBlue {
    width: calc(var(--size-48) * 0.78);
    height: calc(var(--size-48) * 0.78);
    left: calc(var(--size-24) * -1);
  }

  .containerOrnamentOrange {
    width: calc(var(--size-560) * 0.72);
    height: calc(var(--size-560) * 0.72);
  }

  .containerOrnamentWhite {
    width: calc(var(--size-80) * 0.78);
    height: calc(var(--size-80) * 0.78);
    right: calc(var(--size-32) * -1);
  }

  .textParagraph {
    height: var(--size-64);
  }

  @keyframes containerOrnamentBlue {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(var(--size-80));
    }
    100% {
      transform: translateY(0);
    }
  }
}

@media screen and (max-width: 820px) {
  .containerContent {
    gap: var(--size-48);
  }

  .containerImage {
    width: 40%;
  }

  .containerCards {
    width: 100%;
    max-width: 100%;
  }

  .containerOrnamentBlue {
    display: none;
  }

  .containerOrnamentOrange {
    display: none;
  }

  .containerOrnamentWhite {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .containerSection {
    padding: var(--size-48) var(--size-16);
    gap: var(--size-48);
  }

  .containerContent {
    flex-direction: column;
    gap: var(--size-32);
  }

  .containerImage,
  .containerCards {
    width: 100%;
    max-width: 100%;
  }

  .containerCard.cardHovered {
    transform: scale(1, 1);
  }

  .containerCard {
    padding: var(--size-20) var(--size-12);
  }

  .containerImage {
    pointer-events: none;
  }

  .containerCorner {
    width: var(--size-40);
    border-radius: 0 var(--size-2) 0 var(--size-24);
  }

  .textTitle,
  .textNumber {
    font-size: var(--size-14);
  }

  .containerImage {
    height: calc(var(--size-720) * 0.68);
    max-height: calc(var(--size-720) * 0.68);
  }

  .containerMockup {
    height: calc(var(--size-720) * 0.68);
  }

  .containerDevice {
    aspect-ratio: auto;
    width: auto;
    height: calc(var(--size-720) * 0.68);
  }

  .containerMask {
    width: 219px;
    height: calc(var(--size-720) * 0.68);
  }
}
