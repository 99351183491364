/* Text */

.textHeaderLight,
.textHeaderBold,
.textHeaderLink {
  position: relative;
  letter-spacing: var(--letter-spacing-header-big);
}

.textHeaderLight {
  font-size: var(--size-72);
  line-height: var(--size-72);
  font-weight: var(--font-thin);
  color: var(--surface-primary);
}

.textHeaderBold {
  font-size: var(--size-72);
  line-height: var(--size-72);
  font-weight: var(--font-semibold);
  color: var(--surface-primary);
}

.textHeaderLink {
  text-decoration: underline;
  font-size: var(--size-72);
  line-height: var(--size-72);
  font-weight: var(--font-semibold);
  color: var(--surface-primary);
}

.textParagraph {
  flex: 1;
  max-width: 640px;
  font-size: var(--size-20);
  line-height: var(--size-40);
  color: var(--surface-primary);
  letter-spacing: var(--letter-spacing-default);
}

.textBold {
  flex: 1;
  max-width: 640px;
  font-size: var(--size-20);
  line-height: var(--size-40);
  color: var(--surface-primary);
  font-weight: var(--font-bold);
  letter-spacing: var(--letter-spacing-default);
}

/* Main Containers */

.containerHeaders {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}

.containerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--size-24);
  text-align: left;
}

/* Media Queries */

@media screen and (max-width: 1536px) {
  .textBold,
  .textParagraph {
    font-size: var(--size-16);
    line-height: var(--size-32);
  }

  .textParagraph,
  .textBold {
    max-width: 560px;
  }

  .containerHeaders {
    gap: var(--size-16);
  }
}

@media screen and (max-width: 1440px) {
  .textHeaderLight,
  .textHeaderBold,
  .textHeaderLink {
    font-size: var(--size-64);
    line-height: var(--size-64);
  }
}

@media screen and (max-width: 1280px) {
  .textHeaderLight,
  .textHeaderBold,
  .textHeaderLink {
    font-size: var(--size-56);
    line-height: var(--size-56);
  }

  .textParagraph,
  .textBold {
    max-width: 540px;
    font-size: var(--size-16);
  }
}

@media screen and (max-width: 1024px) {
  .textParagraph {
    max-width: 472px;
  }

  .textHeaderLight,
  .textHeaderBold,
  .textHeaderLink {
    font-size: var(--size-48);
    line-height: var(--size-48);
  }
}

@media screen and (max-width: 820px) {
  .textHeaderLight,
  .textHeaderBold,
  .textHeaderLink {
    font-size: var(--size-48);
    line-height: var(--size-48);
  }

  .textParagraph {
    max-width: 100%;
  }

  .containerContent {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--size-32);
  }
}

@media screen and (max-width: 768px) {
  .textHeaderLight,
  .textHeaderBold,
  .textHeaderLink {
    font-size: var(--size-40);
    line-height: var(--size-40);
    letter-spacing: var(--letter-spacing-header-small);
  }

  .containerContent {
    width: 100%;
  }

  .containerHeaders {
    width: 100%;
    align-items: flex-start;
    gap: var(--size-8);
  }
}
