/* Main Containers */

.containerIcons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--size-64);
}

.containerContent {
  width: 100%;
  max-width: 1520px;
  display: flex;
  flex-direction: row;
  gap: var(--size-128);
  align-items: center;
  justify-content: center;
}

.containerSection {
  width: 100%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--size-128) var(--size-200);
  position: relative;
  gap: var(--size-128);
  box-sizing: border-box;
}

.containerMotion {
  width: 100%;
  max-width: 1520px;
}

@media screen and (max-width: 1536px) {
  .containerIcons {
    gap: var(--size-48);
  }

  .containerSection {
    padding: var(--size-80) var(--size-104);
    gap: var(--size-96);
  }
}

@media screen and (max-width: 1440px) {
  .containerIcons {
    gap: var(--size-32);
  }

  .containerSection {
    padding: var(--size-80);
  }
}

@media screen and (max-width: 1280px) {
  .containerSection {
    padding: var(--size-80) var(--size-64);
    gap: var(--size-80);
  }
}

@media screen and (max-width: 1024px) {
  .containerSection {
    padding: var(--size-64) var(--size-48);
    gap: var(--size-64);
  }

  @media screen and (max-width: 768px) {
    .containerSection {
      padding: var(--size-48) var(--size-16);
      gap: var(--size-48);
    }
  }
}
