.textBullet {
    font-size: var(--size-18);
    line-height: var(--size-32);
    color: var(--surface-accent);
    font-weight: var(--font-medium);
}

.containerBullet{
    min-width: var(--size-10);
    min-height: var(--size-10);
    width: var(--size-10);
    height: var(--size-10);
    background-color: var(--on-surface-accent-active);
    margin-top: var(--size-8);
}

.containerContent {
    display: flex;
    flex-direction: row;
    gap: var(--size-12);
}

@media screen and (max-width: 768px) {
  .textBullet {
    font-size: var(--size-16);
  }
}