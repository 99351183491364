.containerText {
  font-size: var(--size-18);
  line-height: var(--size-32);
  color: var(--surface-secondary);
}

@media screen and (max-width: 768px) {
  .containerText {
    font-size: var(--size-16);
  }
}