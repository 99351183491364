/* PrivacyPolicy.module.css */

.containerMenu {
  max-width: 24%;
  width: 100%;
}

.containerList {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--size-80);
}

.containerPage {
  display: flex;
  flex-direction: column;
  gap: var(--size-128);
  max-width: 1520px;
  width: 100%;
  position: relative;
}

.containerContent {
  display: flex;
  flex-direction: column;
  gap: var(--size-128);
  align-items: center;
  justify-content: space-between;
  max-width: 1920px;
  box-sizing: border-box;
  position: relative;
  padding: var(--size-0) var(--size-200);
}

.containerSection {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: var(--size-128);
  position: relative;
}

@media screen and (max-width: 1536px) {
  .containerSection {
    gap: var(--size-80);
  }

  .containerContent {
    padding: var(--size-0) var(--size-104);
    gap: var(--size-80);
  }
}

@media screen and (max-width: 1366px) {
  .containerSection {
    gap: var(--size-64);
  }

  .containerContent {
    padding: var(--size-0) var(--size-80);
    gap: var(--size-64);
  }
}

@media screen and (max-width: 1280px) {
  .containerSection {
    gap: var(--size-48);
  }

  .containerContent {
    padding: var(--size-0) var(--size-64);
  }
}

@media screen and (max-width: 1024px) {
  .containerContent {
    padding: var(--size-0) var(--size-48);
  }
}

@media screen and (max-width: 768px) {
  .containerSection {
    gap: var(--size-32);
  }
  .containerContent {
    padding: var(--size-0) var(--size-24);
    gap: var(--size-40);
  }
}

@media screen and (max-width: 376px) {
  .containerContent {
    padding: var(--size-0) var(--size-16);
  }
}
