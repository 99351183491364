.containerText {
  font-size: var(--size-20);
  line-height: var(--size-32);
  color: var(--on-surface-accent-inactive);
  font-weight: var(--font-medium);
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .containerText {
    font-size: var(--size-18);
  }
}
