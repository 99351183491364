.containerParagraph {
  gap: var(--size-12);
}

.containerContent {
  gap: var(--size-32);
}

.containerBullets {
  gap: var(--size-16);
  padding: var(--size-4) var(--size-0);
}

.containerSection {
  gap: var(--size-128);
}

.containerSection,
.containerContent,
.containerBullets,
.containerParagraph {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1536px) {
  .containerSection {
    gap: var(--size-80);
  }
}

@media screen and (max-width: 1366px) {
  .containerSection {
    gap: var(--size-64);
  }
}

@media screen and (max-width: 1280px) {
  .containerSection {
    gap: var(--size-48);
  }
}

@media screen and (max-width: 768px) {
  .containerSection {
    gap: var(--size-32);
  }

  .containerContent {
    gap: var(--size-24);
  }
}


