/* Shared Styles */
.containerHeader {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--size-24);
}

.textHeaderBold {
  font-weight: var(--font-semibold);
  color: var(--surface-primary);
}

.textHeaderLight {
  font-weight: var(--font-thin);
  color: var(--surface-primary);
}

.textParagraph {
  font-size: var(--size-16);
  line-height: var(--size-32);
  font-weight: var(--font-light);
  color: var(--surface-primary);
}

/* Main Containers */
.containerContent {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-48);
  max-width: 50%;
  text-align: left;
}

/* Media Queries */
@media screen and (max-width: 1536px) {
  .containerContent {
    gap: var(--size-40);
  }

  .containerHeader {
    gap: var(--size-16);
  }

  .textHeaderLight,
  .textHeaderBold {
    font-size: var(--size-64);
    line-height: var(--size-64);
  }

  .textParagraph {
    font-size: var(--size-14);
    line-height: var(--size-28);
  }
}

@media screen and (max-width: 1280px) {
  .textHeaderLight,
  .textHeaderBold {
    font-size: var(--size-48);
    line-height: var(--size-48);
  }

  .textParagraph {
    font-size: var(--size-12);
    line-height: var(--size-24);
  }

  .containerContent {
    gap: var(--size-32);
  }
}

@media screen and (max-width: 1024px) {
  .containerContent {
    max-width: 100%;
    width: 100%;
    align-items: center;
  }

  .textHeaderBold,
  .textHeaderLight {
    text-align: center;
    font-size: var(--size-64);
    line-height: var(--size-64);
  }

  .textParagraph {
    text-align: center;
    font-size: var(--size-18);
    line-height: var(--size-40);
  }
}

@media screen and (max-width: 820px) {
  .textHeaderBold,
  .textHeaderLight {
    font-size: var(--size-56);
    line-height: var(--size-56);
  }

  .textParagraph {
    font-size: var(--size-16);
    line-height: var(--size-32);
  }
}

@media screen and (max-width: 768px) {
  .containerContent {
    gap: var(--size-32);
  }

  .containerHeader {
    gap: var(--size-8);
  }

  .textHeaderLight {
    font-size: var(--size-40);
    line-height: var(--size-40);
  }

  .textHeaderBold {
    font-size: var(--size-40);
    line-height: var(--size-64);
  }
}
