.containerText {
  font-size: var(--size-32);
  line-height: var(--size-32);
  color: var(--on-surface-accent-active);
}

@media screen and (max-width: 1280px) {
  .containerText {
    font-size: var(--size-28);
    line-height: var(--size-28);
  }
}

@media screen and (max-width: 768px) {
  .containerText {
    font-size: var(--size-24);
    line-height: var(--size-24);
  }
}
