/* Animations */

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Scroll To Top */

.buttonScroll {
  width: var(--size-200);
  height: var(--size-200);
  position: relative;
  transition: calc(var(--d) * 2) var(--e);
}

.buttonScroll:hover {
  transform: scale(1.1);
  transition: calc(var(--d) * 2) var(--e);
}

.buttonScroll:hover > .iconScroll {
  transform: rotate(45deg);
  transition: calc(var(--d) * 2) var(--e);
}
.buttonScroll > .iconScroll {
  transition: calc(var(--d) * 2) var(--e);
}

.imageScroll {
  position: relative;
  width: var(--size-200);
  height: auto;
  object-fit: cover;
  animation-name: rotation;
  animation-duration: 8000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.iconScroll {
  position: absolute;
  top: calc(50% - (var(--size-32) / 2));
  left: calc(50% - (var(--size-32) / 2));
  width: var(--size-32);
  height: var(--size-32);
}

/* Images */

.imageLogo {
  position: relative;
  height: var(--size-32);
  object-fit: cover;
}

/* Text */

.textParagraph {
  font-weight: var(--font-regular);
  font-size: var(--size-16);
  line-height: var(--size-32);
  color: var(--surface-primary);
}

/* Content Containers */

.containerHeader {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--size-32);
}

.containerLinks {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.containerCopyright {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--size-32);
  width: 100%;
}

.containerCopyrightContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.containerCopyrightLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--size-48);
}

.containerBottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-48);
  align-self: stretch;
  justify-content: space-between;
}

.containerLinks {
  flex-shrink: 0;
  justify-content: flex-start;
  gap: var(--size-128);
}

/* Main Containers */

.containerContent {
  width: 100%;
  max-width: 1520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: var(--size-96);
}

.containerSection {
  display: flex;
  background-color: var(--surface-tertiary);
  flex-direction: column;
  padding: var(--size-128) var(--size-200);
  gap: var(--size-80);
  text-align: right;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
}

.containerMotion {
  width: 100%;
  max-width: 1520px;
}

@media screen and (max-width: 1536px) {
  .containerContent {
    gap: var(--size-64);
  }

  .containerSection {
    padding: var(--size-80) var(--size-104);
  }
}

@media screen and (max-width: 1366px) {
  .containerSection {
    padding: var(--size-80);
  }

  .buttonScroll,
  .imageScroll {
    width: var(--size-160);
    height: var(--size-160);
  }

  .iconScroll {
    position: absolute;
    top: calc(50% - (var(--size-24) / 2));
    left: calc(50% - (var(--size-24) / 2));
    width: var(--size-24);
    height: var(--size-24);
  }

  .imageLogo {
    height: var(--size-24);
  }

  .textParagraph {
    font-size: var(--size-14);
    line-height: var(--size-24);
  }
}

@media screen and (max-width: 1280px) {
  .containerSection {
    padding: var(--size-64);
  }
}

@media screen and (max-width: 1024px) {
  .containerSection {
    padding: var(--size-64) var(--size-48);
  }

  .containerContent {
    gap: var(--size-48);
  }

  .containerLinks {
    gap: var(--size-104);
  }
}

@media screen and (max-width: 820px) {
  .containerSection {
    padding: var(--size-48) var(--size-48);
  }

  .containerContent {
    gap: var(--size-48);
  }

  .containerLinks {
    gap: var(--size-64);
  }

  .buttonScroll,
  .imageScroll {
    width: var(--size-128);
    height: var(--size-128);
  }

  .iconScroll {
    position: absolute;
    top: calc(50% - (var(--size-24) / 2));
    left: calc(50% - (var(--size-24) / 2));
    width: var(--size-24);
    height: var(--size-24);
  }
}

@media screen and (max-width: 768px) {
  .containerSection {
    padding: var(--size-48) var(--size-24);
    align-items: center;
    justify-content: center;
  }

  .containerBottom {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--size-32);
  }

  .containerLinks {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .containerCopyright {
    justify-content: center;
    align-items: center;
  }

  .containerCopyrightContent {
    flex-direction: column-reverse;
    gap: var(--size-40);
  }

  .containerCopyrightLinks {
    flex-direction: column;
    gap: var(--size-40);
  }

  .textParagraph {
    text-align: center;
  }
}

@media screen and (max-width: 376px) {
  .containerSection {
    padding: var(--size-48) var(--size-16);
  }
}
