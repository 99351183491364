/* Text */

.textTitle {
  align-self: stretch;
  position: relative;
  font-size: var(--size-32);
  line-height: var(--size-48);
  letter-spacing: var(--letter-spacing-default);
  color: var(--surface-primary);
}

/* Main Containers */

.containerContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--size-80);
  text-align: left;
}

@media screen and (max-width: 820px) {
  .containerContent {
    gap: var(--size-56);
  }
}

@media screen and (max-width: 768px) {
  .containerContent {
    flex-direction: column;
    gap: var(--size-40);
    align-items: center;
    justify-content: center;
  }
}
