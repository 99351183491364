/* Main Containers */

.containerContent {
  width: 100%;
  max-width: 1520px;
  gap: var(--size-80);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: visible;
}

.containerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--surface-primary);
  flex-direction: column;
  align-items: center;
  padding: var(--size-128) var(--size-200);
  gap: var(--size-128);
  text-align: left;
  overflow: visible;
}

.containerMotion {
  width: 100%;
  max-width: 1520px;
}

/* Media Queries */

@media screen and (max-width: 1536px) {
  .containerSection {
    padding: var(--size-80) var(--size-104);
    gap: var(--size-96);
  }

  .containerContent {
    gap: var(--size-48);
  }
}

@media screen and (max-width: 1366px) {
  .containerSection {
    padding: var(--size-80);
  }

  .containerContent {
    gap: var(--size-48);
  }
}

@media screen and (max-width: 1280px) {
  .containerSection {
    padding: var(--size-80) var(--size-64);
    gap: var(--size-80);
  }

  .containerContent {
    gap: var(--size-48);
  }
}

@media screen and (max-width: 1024px) {
  .containerSection {
    padding: var(--size-64) var(--size-48);
    gap: var(--size-64);
  }

  .containerContent {
    gap: var(--size-40);
  }
}

@media screen and (max-width: 820px) {
  .containerSection {
    padding: var(--size-48) var(--size-48);
    gap: var(--size-48);
  }

  .containerContent {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .containerSection {
    padding: var(--size-48) var(--size-16);
    gap: var(--size-48);
  }
}

@media screen and (max-width: 376px) {
  .containerSection {
    padding: var(--size-48) var(--size-16);
  }
}

