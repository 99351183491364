/* Images */

.imagePerson {
  position: relative;
  width: var(--size-80);
  height: auto;
  object-fit: cover;
}

.imageLogo {
  height: var(--size-64);
  width: auto;
  position: relative;
  object-fit: contain;
}

.imageRight {
  position: absolute;
  bottom: var(--size-48);
  right: var(--size-40);
  width: 21%;
  height: auto;
  object-fit: contain;
  z-index: 1;
  filter: drop-shadow(var(--shadow-hero-mockup-small));
}

.imageLeft {
  position: absolute;
  bottom: var(--size-16);
  right: var(--size-240);
  width: 24%;
  height: auto;
  object-fit: contain;
  z-index: 2;
  filter: drop-shadow(var(--shadow-hero-mockup-small));
}

/* Header */

.containerHeader,
.containerTextHeaders {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: var(--font-roag);
  gap: var(--size-32);
}

.containerTextHeaders {
  gap: var(--size-0);
}

.textHeaderSmall,
.textHeaderBig {
  text-transform: uppercase;
  font-weight: var(--font-black);
}

.textHeaderSmall {
  font-size: var(--size-32);
  line-height: var(--size-48);
  color: var(--surface-primary);
}

.textHeaderBig {
  font-size: var(--size-64);
  line-height: var(--size-56);
  color: var(--surface-primary);
}

/* Quote */

.containerQuote {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--size-16);
  font-size: var(--header-bold-h6-size);
}

.textQuoteTitle {
  letter-spacing: var(--letter-spacing-default);
  font-size: var(--size-24);
  line-height: var(--size-40);
  color: var(--surface-primary);
  font-weight: var(--font-bold);
}

.textQuoteParagraph {
  font-size: var(--size-16);
  line-height: var(--size-32);
  color: var(--on-surface-accent-disabled-light);
}

/* Text */

.textParagraph {
  color: var(--surface-primary);
  font-size: var(--size-20);
  line-height: var(--size-40);
  max-width: 640px;
}

/* Buttons */

.containerDownload {
  display: flex;
  flex-direction: column;
  background-color: var(--surface-primary);
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: var(--size-32) var(--size-64) var(--size-64) var(--size-64);
  box-sizing: border-box;
  border-bottom-left-radius: var(--size-48);
  border-bottom-right-radius: var(--size-48);
  gap: var(--size-24);
}

.textDownloadLight,
.textDownloadBold {
  font-size: var(--size-40);
  line-height: var(--size-56);
  letter-spacing: var(--letter-spacing-header-big);
  color: var(--surface-secondary);
}

.textDownloadLight {
  font-weight: var(--font-light);
}

.textDownloadBold {
  font-weight: var(--font-semibold);
  padding-left: var(--size-8);
}

.containerButtons {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--size-32);
}

.buttonAppStore,
.buttonGooglePlay {
  position: relative;
  width: var(--size-200);
  height: auto;
  object-fit: cover;
  cursor: pointer;
}

/* Person Details */

.containerPersonImage,
.containerPersonDetails {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.containerPersonImage {
  flex-direction: row;
  align-items: center;
  gap: var(--size-16);
}

.containerPerson {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: var(--size-20);
}

.textPersonName {
  font-size: var(--size-20);
  line-height: var(--size-40);
  color: var(--surface-primary);
  font-weight: var(--font-bold);
}

.textPersonOccupation {
  font-size: var(--size-16);
  line-height: var(--size-32);
  color: var(--on-surface-accent-disabled-light);
}

/* Main Containers */

.containerArticle {
  max-width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--size-48);
  z-index: 0;
}

.containerContent {
  border-radius: var(--size-48);
  width: 100%;
  max-width: 1520px;
  overflow: hidden;
  display: inline-block;
  padding: var(--size-64) var(--size-64) var(--size-264) var(--size-64);
  box-sizing: border-box;
  position: relative;
  background-image: url(/public/backgrounds/backgroundTestimonials.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  transition: calc(var(--d) * 2) var(--e);
  overflow: visible;
  box-shadow: var(--shadow-card);
}

.containerContent:hover {
  border-radius: var(--size-80);
  transition: calc(var(--d) * 2) var(--e);
}

.containerContent:hover .containerDownload {
  border-bottom-left-radius: var(--size-80);
  border-bottom-right-radius: var(--size-80);
  transition: calc(var(--d) * 2) var(--e);
}

.containerContent .containerDownload {
  transition: calc(var(--d) * 2) var(--e);
}

.containerContent:hover .imageLeft {
  transform: rotate(5deg) translateX(var(--size-32));
  transition: calc(var(--d) * 2) var(--e);
}

.containerContent:hover .imageRight {
  transform: rotate(5deg) translateX(var(--size-16));
  transition: calc(var(--d) * 2) var(--e);
}

.containerContent .imageLeft,
.imageRight {
  transition: calc(var(--d) * 2) var(--e);
}

.containerSection {
  background-color: var(--surface-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--size-128) var(--size-200);
  gap: var(--size-128);
  box-sizing: border-box;
  text-align: left;
  align-self: stretch;
}

.containerMotion {
  max-width: 1520px;
  width: 100%;
}

/* Media Queries */

@media screen and (max-width: 1536px) {
  .containerSection {
    padding: var(--size-80) var(--size-104);
  }

  .containerContent {
    padding: var(--size-48);
  }

  .containerArticle {
    gap: var(--size-48);
    max-width: 50%;
  }

  .containerDownload {
    padding: var(--size-32) var(--size-48) var(--size-48) var(--size-48);
  }

  .textQuoteParagraph {
    font-size: var(--size-16);
  }

  .textHeaderSmall {
    font-size: var(--size-24);
    line-height: var(--size-32);
  }

  .textHeaderBig {
    font-size: var(--size-48);
    line-height: var(--size-40);
  }

  .containerArticle {
    gap: var(--size-32);
  }

  .textQuoteParagraph {
    line-height: var(--size-32);
  }

  .imageLogo {
    height: var(--size-48);
  }

  .imagePerson {
    width: var(--size-56);
  }

  .textQuoteTitle {
    font-size: var(--size-20);
    line-height: var(--size-32);
  }

  .textPersonName {
    font-size: var(--size-16);
    line-height: var(--size-32);
  }

  .textPersonOccupation {
    font-size: var(--size-12);
    line-height: var(--size-24);
  }

  .buttonAppStore,
  .buttonGooglePlay {
    width: var(--size-160);
  }

  .textParagraph {
    font-size: var(--size-16);
    line-height: var(--size-32);
  }
}

@media screen and (max-width: 1440px) {
  .containerSection {
    gap: var(--size-96);
  }

  .textQuoteParagraph {
    font-size: var(--size-14);
  }
}

@media screen and (max-width: 1366px) {
  .containerSection {
    padding: var(--size-80);
    gap: var(--size-96);
  }
}

@media screen and (max-width: 1280px) {
  .containerSection {
    padding: var(--size-80) var(--size-64);
    gap: var(--size-80);
  }

  .containerButtons {
    gap: var(--size-16);
  }

  .containerContent {
    border-radius: var(--size-40);
  }

  .containerContent:hover {
    border-radius: var(--size-72);
  }

  .containerDownload {
    gap: var(--size-16);
    padding-top: var(--size-24);
    border-bottom-left-radius: var(--size-40);
    border-bottom-right-radius: var(--size-40);
  }

  .containerArticle {
    gap: var(--size-24);
  }

  .containerQuote {
    gap: var(--size-8);
  }

  .textHeaderBig {
    font-size: var(--size-40);
    line-height: var(--size-32);
  }

  .textQuoteParagraph {
    font-size: var(--size-14);
    line-height: var(--size-28);
  }

  .textParagraph {
    font-size: var(--size-14);
  }

  .imageRight {
    width: 20%;
    bottom: var(--size-48);
    right: var(--size-40);
  }

  .imageLeft {
    width: 23%;
    bottom: var(--size-16);
    right: var(--size-200);
  }

  .buttonAppStore,
  .buttonGooglePlay {
    width: var(--size-144);
  }

  .textDownloadBold,
  .textDownloadLight {
    font-size: var(--size-32);
    line-height: var(--size-48);
    letter-spacing: var(--letter-spacing-header-small);
  }
}

@media screen and (max-width: 1024px) {
  .containerSection {
    padding: var(--size-64) var(--size-48);
    gap: var(--size-64);
  }

  .containerArticle {
    max-width: 60%;
  }

  .textHeaderBig {
    max-width: 100%;
    font-size: var(--size-40);
  }

  .imageLeft {
    width: 30%;
    right: var(--size-40);
  }
}

@media screen and (max-width: 820px) {
  .containerSection {
    gap: var(--size-32);
  }

  .containerContent {
    border-radius: var(--size-32);
    padding: var(--size-32);
  }

  .containerContent:hover {
    border-radius: var(--size-48);
  }

  .containerArticle {
    max-width: 100%;
  }

  .containerDownload {
    border-bottom-left-radius: var(--size-32);
    border-bottom-right-radius: var(--size-32);
  }
}

@media screen and (max-width: 768px) {
  .containerSection {
    gap: var(--size-48);
    padding: var(--size-48) var(--size-16);
    margin-top: var(--size-0) !important;
  }

  .containerContent {
    border-radius: var(--size-32);
    padding: var(--size-32) var(--size-24) var(--size-24) var(--size-24);
  }

  .containerContent:hover {
    border-radius: var(--size-32);
  }

  .containerDownload {
    padding: var(--size-16) var(--size-16) var(--size-32) var(--size-16);
    border-bottom-right-radius: var(--size-32);
    border-bottom-left-radius: var(--size-32);
  }

  .imageLogo {
    height: var(--size-24);
  }

  .imagePerson {
    width: var(--size-40);
    height: var(--size-40);
  }

  .textPersonName {
    line-height: var(--size-24);
  }

  .textPersonOccupation {
    font-size: var(--size-10);
  }

  .containerButtons {
    gap: var(--size-16);
  }

  .buttonAppStore {
    width: 100%;
  }

  .buttonGooglePlay {
    width: 100%;
  }

  .containerPerson {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: var(--size-24);
  }

  .textDownloadBold,
  .textDownloadLight {
    font-size: var(--size-24);
  }
}

@media screen and (max-width: 376px) {
  .containerSection {
    gap: var(--size-32);
    padding: var(--size-48) var(--size-16);
  }

  .containerContent {
    padding: var(--size-24);
  }
}
