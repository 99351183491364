/* Text */

.linkText {
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: var(--size-16);
  line-height: var(--size-24);
  color: var(--surface-primary);
  font-weight: var(--font-medium);
}

.linkText::before,
.linkText::after {
  content: attr(data-replace);
  position: absolute;
  width: 100%;
  left: 0;
}

.linkText::before {
  background-color: var(--on-surface-accent-active);
  height: var(--size-2);
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.linkText::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  color: var(--on-surface-accent-active);
}

.linkText:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}

.linkText:hover::after {
  transform: translate3d(0, 0, 0);
}

.linkText span {
  display: inline-block;
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.linkText:hover span {
  transform: translate3d(-200%, 0, 0);
}

/* Main Containers */

.linkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1366px) {
  .linkText {
    font-size: var(--size-14);
    line-height: var(--size-24);
  }
}
